import { ProjectAuthType } from "@prisma/client";
import { CurrentProjectProvider } from "lib/context/current-project-context";
import { EmbedProvider } from "lib/context/embed-context";
import { checkFeature } from "lib/hooks/useFeatureAccessCheck";
import { pagePublicSchema } from "server/schemas/pages";
import { projectForPublishedPageSchema } from "server/schemas/projects";
import { Project } from "utils/trpc";
import { z } from "zod";
import { ProjectError } from "./ProjectError";
import { PublishedPageRenderer } from "./PublishedPageRenderer";

type ProjectForPublishedPage = z.infer<typeof projectForPublishedPageSchema>;
type Page = z.infer<typeof pagePublicSchema>;

export function PublishedPage({
  project,
  page,
  isEmbed,
  noBackground,
}: {
  project: ProjectForPublishedPage;
  page: Page;
  isEmbed: boolean;
  noBackground?: boolean;
}) {
  const creator = {
    ...project.creator,
    paidFeatureAccess: project.paidFeatureAccess,
  };
  const hasManagedUsersAccess = checkFeature("managedUsers", creator);
  const hasPasswordProtectAccess = checkFeature("passwordProtect", creator);

  if (
    project.authType !== ProjectAuthType.None &&
    !(hasManagedUsersAccess || hasPasswordProtectAccess)
  ) {
    return (
      <ProjectError
        title="Interface cannot be displayed"
        message="Interface access settings are enabled for your account, but your account is not upgraded; please consider upgrading your account or clear access settings to view the project."
      />
    );
  }

  return (
    // This `project as Project` business is terrible... but it's what's
    // currently in place in PublishedPage/index.tsx and is a lot to unwind.
    <CurrentProjectProvider project={project as Project}>
      <EmbedProvider embed={{ isEmbed, noBackground }}>
        <PublishedPageRenderer
          pageId={page.id}
          pageTitle={page.title}
          isEmbed={isEmbed}
        />
      </EmbedProvider>
    </CurrentProjectProvider>
  );
}
