import { useEffect, useMemo } from "react";
import { trpc } from "utils/trpc";
import Head from "next/head";
import { PageContextProvider } from "lib/context/page-context";
import { Page } from "components/Page";
import PoweredByZapier from "components/PoweredByZapier";
import GATrackingScript from "components/GATrackingScript";
import { ProjectAuthType } from "@prisma/client";
import { useTrackingContext } from "observability/tracking";
import { navigationItemsForPages } from "lib/utils/navigationItemForPages";
import { PublishedPageSpinner } from "block-system/components/PublishedPageSpinner";
import { useCurrentProject } from "lib/context/current-project-context";
import { CurrentPagesProvider } from "lib/context/current-pages-context";
import { DatasourcesProvider } from "lib/context/datasources-provider";
import { CurrentPageProvider } from "lib/context/current-page-context";
import { useFeatureAccessCheck } from "lib/hooks/useFeatureAccessCheck";
import { SSR_STALE_TIME } from "lib/constants";
import { MdOutlineFlag } from "react-icons/md";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

type PublishedPageRendererProps = {
  pageId: string | undefined;
  pageTitle: string;
  isEmbed: boolean;
};

export function PublishedPageRenderer({
  pageId,
  pageTitle,
  isEmbed,
}: PublishedPageRendererProps) {
  const project = useCurrentProject();
  const projectId = project.id;

  const hasAnalyticsAccess = useFeatureAccessCheck("analytics", {
    ...project.creator,
    paidFeatureAccess: project.paidFeatureAccess,
  });

  // We don't have account details on published pages
  // So inorder to check if the user is paid user, we are checking if the user has access to a premium feature
  const isPaidUser = hasAnalyticsAccess;

  const {
    data: page,
    error: pageError,
    isLoading: isPageLoading,
  } = trpc.pages.get.useQuery(
    { id: pageId ?? "" },
    { staleTime: SSR_STALE_TIME }
  );
  const {
    data: maybePages,
    error: pagesError,
    isLoading: isPagesLoading,
  } = trpc.pages.list.useQuery(
    { projectId },
    {
      enabled: !!project,
      staleTime: SSR_STALE_TIME,
    }
  );
  const pages = useMemo(() => maybePages ?? [], [maybePages]);
  const allPages = useMemo(
    () => navigationItemsForPages(pages, project, isEmbed),
    [isEmbed, pages, project]
  );

  const isValidAuthType =
    project?.authType === ProjectAuthType.Consumer ||
    project?.authType === ProjectAuthType.Stytch;

  const { data: consumerInfo } = trpc.consumers.me.useQuery(
    { projectId },
    {
      staleTime: SSR_STALE_TIME,
      enabled: isValidAuthType,
    }
  );

  const favicon = project?.favicon?.url;

  const { emitConsumerInteractionEvent } = useTrackingContext();

  useEffect(() => {
    /**
     * Don't emit the event if the page is loading or the project is hidden (as in the case a
     * standalone chatbot page)
     */
    if (pageId && projectId && !project.isHidden) {
      emitConsumerInteractionEvent({
        interfaces_project_id: projectId,
        interfaces_page_id: pageId,
        embedded_flag: isEmbed,
        interaction_goal: "see page",
        event_action: "enter",
      });
    }
  }, [
    isPageLoading,
    emitConsumerInteractionEvent,
    projectId,
    pageId,
    isEmbed,
    project.isHidden,
  ]);

  const hasChatbot = useMemo(() => {
    return (
      page?.content.blocks.some((b) => b.type === "chatbot-block") ?? false
    );
  }, [page]);

  if (isPagesLoading || isPageLoading) {
    return (
      <PublishedPageSpinner
        ariaLabelSuffix={[
          isPagesLoading ? "All pages" : "",
          isPageLoading ? "Page" : "",
        ]
          .filter(Boolean)
          .join(", ")}
      />
    );
  }

  if (!page || !project || pagesError || pageError)
    throw new Error("Page did not load");

  return (
    <>
      <div
        className={cn("min-h-screen", { "flex flex-col": project.isHidden })}
      >
        <Head>
          <title>{pageTitle}</title>
          {favicon ? (
            <link rel="icon" type="image/png" href={favicon} key="favicon" />
          ) : null}
        </Head>
        <PageContextProvider
          isEditing={false}
          isEmbedded={isEmbed}
          accountId={project?.accountId}
          projectId={page.projectId}
          pageId={page.id}
          allPages={allPages}
          globalNavigation={project.globalNavigation}
          includeLogo={project.includeLogo}
          navigationLinks={project.navigationLinks}
          projectName={project.name}
          projectPublicName={project.publicName}
          projectSlug={project.slug}
          navigationEnabled={page.navigationEnabled}
          logo={project.logo}
        >
          <CurrentPageProvider page={page}>
            <CurrentPagesProvider pages={pages}>
              <DatasourcesProvider>
                <Page
                  blocks={page.content.blocks ?? []}
                  consumerInfo={consumerInfo}
                />
              </DatasourcesProvider>
            </CurrentPagesProvider>
          </CurrentPageProvider>
          {!project.isHidden && !isEmbed && (
            <PoweredByZapier hasChatbot={hasChatbot} position="fixed-portal" />
          )}
          {!isPaidUser && !project.isHidden ? <ReportInterfaceButton /> : null}
        </PageContextProvider>
      </div>
      {hasAnalyticsAccess && project?.googleAnalyticsTrackingId ? (
        <GATrackingScript trackingId={project.googleAnalyticsTrackingId} />
      ) : null}
    </>
  );
}

const REPORT_URL = "https://interfaces-report.zapier.app/report-form";

export function ReportInterfaceButton() {
  const interfacesTheme = useInterfacesTheme();

  return (
    <div className="fixed bottom-14 right-5">
      <button
        className={cn(
          "flex w-[120px] transform justify-center gap-1 bg-neutral-800 p-2 text-white transition-all hover:scale-105",
          interfacesTheme ? "rounded-medium" : "rounded"
        )}
        onClick={() => {
          if (typeof window === "undefined") return;
          window.open(REPORT_URL, "_blank");
        }}
      >
        <MdOutlineFlag size={20} />
        Report
      </button>
    </div>
  );
}
